import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PayProvidersView from '../views/PayProvidersView.vue'
import ProviderView from '../views/ProviderView.vue'
import AmountView from '../views/AmountView.vue'
import DenominationsView from '../views/DenominationsView.vue'
import PayView from '../views/PayView.vue'
import PrivacyPolicy from '../views/PrivacyPolicyView'
import TermsAndConditions from '../views/TermsAndConditionsView'
import CancellationRefundPolicy from '../views/CancellationRefundPolicyView'
import DeliveryAndShippingPolicy from '../views/DeliveryAndShippingPolicyView'
import ContactsCompany from '../views/ContactsCompanyView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/groups/:groupId',
    name: 'groups',
    component: PayProvidersView,
    meta: {
      watchParam: 'groupId'
    }
  },
  {
    path: '/provider/:providerId',
    name: 'provider',
    component: ProviderView
  },
  {
    path: '/denominations/',
    name: 'denominations',
    component: DenominationsView
  },
  {
    path: '/amount/',
    name: 'amount',
    component: AmountView
  },
  {
    path: '/pay/',
    name: 'pay',
    component: PayView,
    props: {
      phone: String,
      amount: String,
      provider: Object
    }
  },
  {
    path: '/privacy-policy/',
    name: 'privacy_policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions/',
    name: 'terms_and_conditions',
    component: TermsAndConditions
  },
  {
    path: '/cancellation-refund-policy/',
    name: 'cancellation_refund_policy',
    component: CancellationRefundPolicy
  },
  {
    path: '/delivery-and-shipping-policy/',
    name: 'delivery_and_shipping_policy',
    component: DeliveryAndShippingPolicy
  },
  {
    path: '/contacts-company/',
    name: 'contacts_company',
    component: ContactsCompany
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
