<template>
  <v-form
    ref="phoneForm"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row class="mt-8" no-gutters justify="center">
        <v-col
          cols="5"
          class="rounded-lg ma-2"
          height="200px"
        >
          <v-img
            :src='provider.image_url'
          />
        </v-col>
      </v-row>
      <li style="display: block" v-for="field in provider.fields" :key="field.field_name" >
        <v-row class="mt-8" no-gutters justify="center">
          <v-col
            cols="12"
          >
            <v-text-field
              :label="field.field_comment"
              :hint="field.example"
              persistent-hint
              outlined
              clearable
              required
              v-model="storage['field'+field.id]"
              :rules="storage['field'+field.id+'Rules']"
              class="label-text"
            ></v-text-field>
          </v-col>
        </v-row>
      </li>
      <v-row class="mt-8" no-gutters justify="center" v-if="storage.provider.type_id !== 5 && !storage.provider.online_verification">
        <v-col
          cols="12"
        >
          <v-text-field
            v-if="storage.provider.type_id !== 4 && storage.provider.type_id !== 2"
            outlined
            label="Enter amount"
            clearable
            required
            v-model="storage.amount"
            :rules="amountRules"
            prefix="AED"
            class="label-text"
          ></v-text-field>
          <v-text-field
            v-if="storage.provider.type_id === 4"
            readonly
            outlined
            label="Enter amount"
            required
            v-model="storage.amount"
            :rules="amountRules"
            prefix="AED"
            class="label-text"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-8" no-gutters justify="center">
        <v-col
          cols="12"
          align="center"
        >
          <v-btn
            :ripple="false"
            @click="prevHistory"
          >
            BACK
          </v-btn>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="setValidate"
          >
            NEXT
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from '@/utils/axios-http'
import { inject, onMounted, ref } from 'vue'
import router from '@/utils/router'

export default {
  name: 'PayProvider',
  props: {
    provider: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const { prevHistory } = inject('history')
    const { storage, setStorage } = inject('storage')
    const phoneForm = ref(null)
    const valid = ref(true)
    let name = 'denominations'
    let voucher = ''
    let account = ''
    let amount = ''
    let tempVoucher = ''
    let data = {}
    let limits = {}

    if (storage.provider.type_id === 4) {
      storage.amount = storage.provider.limits.min
    }

    const setValidate = val => {
      if (phoneForm.value.validate()) {
        setStorage('overlay', true)
        if (!storage.provider.online_verification) {
          if (storage.provider.type_id === 5) {
            name = 'pay'
            voucher = storage.field1
            axios.post(
              process.env.VUE_APP_API_URL + 'vouchers/check?voucher=' + voucher)
              .catch(function (error) {
                if (error.response) {
                  Object.entries(props.provider.fields).forEach(([key, value]) => {
                    storage[`field${value.id}Rules`] = [
                      v => v !== '' || `otp ${voucher} is incorrect`,
                      v => new RegExp(value.regex).test(v) || value.example,
                      v => v !== voucher || error.response.data.message
                    ]
                  })
                  storage.field1 = ''
                  phoneForm.value.validate()
                  setStorage('overlay', false)
                  storage.field1 = voucher
                  amount = 0
                }
              })
              .then(response => {
                if (response) {
                  setStorage('amount', response.data.voucher.amount)
                  setStorage('wallet', response.data.voucher.wallet)
                  setStorage('number', response.data.voucher.number)
                  amount = parseInt(storage.amount)
                  if (!(amount >= props.provider.limits.min && amount <= props.provider.limits.max)) {
                    tempVoucher = voucher
                    amount = 0
                  }
                  Object.entries(props.provider.fields).forEach(([key, value]) => {
                    storage[`field${value.id}Rules`] = [
                      v => v !== '' || `otp ${voucher} is incorrect`,
                      v => new RegExp(value.regex).test(v) || value.example,
                      v => v !== tempVoucher || `otp ${voucher} is incorrect, amount out of limits`
                    ]
                  })
                }
              })
              .then(_ => {
                if (storage.field1 !== '' && amount !== 0) {
                  setStorage('overlay', false)
                  router.push({ name: name })
                } else {
                  storage.field1 = ''
                  phoneForm.value.validate()
                  setStorage('overlay', false)
                  storage.field1 = voucher
                }
              })
          } else {
            if (storage.provider.type_id !== 2) {
              name = 'pay'
            } else {
              for (const key in storage.denominations) {
                if (storage.denominations[key].our === parseInt(storage.amount)) {
                  name = 'pay'
                  if (storage.denominations[key].description) {
                    setStorage('description', storage.denominations[key].description)
                  } else {
                    setStorage('description', storage.denominations[key].them + ' ' + storage.denominations[key].currency_out)
                  }
                }
              }
            }
            setStorage('overlay', false)
            router.push({ name: name })
          }
        } else {
          name = 'amount'
          account = storage.field1
          data = {
            account: account,
            provider_id: storage.provider.id
          }
          axios.post(
            process.env.VUE_APP_API_URL + 'providers/online_verification', data)
            .catch(function (error) {
              if (error.response) {
                console.log(error.response)
                Object.entries(props.provider.fields).forEach(([key, value]) => {
                  storage[`field${value.id}Rules`] = [
                    v => v !== '' || `There is no account for ${account}`,
                    v => new RegExp(value.regex).test(v) || value.example,
                    v => v !== account || `There is no account for ${account}`
                  ]
                })
                storage.field1 = ''
                phoneForm.value.validate()
                setStorage('overlay', false)
                storage.field1 = account
                amount = 0
              }
            })
            .then(response => {
              if (response) {
                if (response.data.verification_info.result) {
                  setStorage('balance', response.data.verification_info.balance)
                  setStorage('cost', response.data.verification_info.cost)
                  setStorage('min_amount_to', response.data.verification_info.min_amount_to)
                  setStorage('session', response.data.verification_info.session)
                  setStorage('receipt', response.data.verification_info.receipt)
                  setStorage('account', response.data.verification_info.account)
                  setStorage('fields_ext', response.data.verification_info.fields_ext)
                  storage.field1 = ''
                } else {
                  Object.entries(props.provider.fields).forEach(([key, value]) => {
                    storage[`field${value.id}Rules`] = [
                      v => v !== '' || `There is no account for ${account}`,
                      v => new RegExp(value.regex).test(v) || value.example,
                      v => v !== account || `There is no account for ${account}`
                    ]
                  })
                  storage.field1 = ''
                  phoneForm.value.validate()
                  setStorage('overlay', false)
                  storage.field1 = account
                }
              }
            })
            .then(_ => {
              if (storage.field1 !== '') {
                storage.field1 = ''
                phoneForm.value.validate()
                storage.field1 = account
              } else {
                let minAmount = storage.provider.limits.min
                if (minAmount < storage.min_amount_to) {
                  minAmount = storage.min_amount_to
                  storage.amount = storage.min_amount_to
                }
                storage.field1 = account
                phoneForm.value.validate()
                setStorage('overlay', false)
                limits = {
                  min: minAmount,
                  max: storage.provider.limits.max
                }
                router.push({ name: name, query: limits })
              }
            })
        }
      }
    }

    const amountRules = [
      v => !!v || 'Amount is required',
      v => (parseInt(v) >= props.provider.limits.min) || `Amount must be greater than ${props.provider.limits.min}`,
      v => (parseInt(v) <= props.provider.limits.max) || `Amount must be less than ${props.provider.limits.max}`
    ]

    Object.entries(props.provider.fields).forEach(([key, value]) => {
      storage[`field${value.id}Rules`] = [
        v => new RegExp(value.regex).test(v) || value.example
      ]
    })

    onMounted(() => {
      Object.entries(props.provider.fields).forEach(([key, value]) => {
        if (storage['field' + value.id] || storage['field' + value.id] === '') {
          phoneForm.value.validate()
        }
      })
      if (storage.amount) {
        phoneForm.value.validate()
      }
    })

    return {
      prevHistory,
      phoneForm,
      storage,
      amountRules,
      valid,
      setValidate
    }
  }
}
</script>
