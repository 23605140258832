<template>
  <v-table class="mt-10">
    <thead>
    <tr class="font-weight-bold">
      <th class="text-left">
        You Are Paying For
      </th>
      <th class="text-left">
        Amount, AED
      </th>
      <th class="text-left">
        *Total Amount, AED
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="bottom-grey">{{storage.provider.name}}</td>
      <td class="bottom-grey">{{storage.amount}}</td>
      <td class="bottom-grey">{{total}}</td>
    </tr>
    </tbody>
    <tbody v-for="field in storage.provider.fields" :key="field.field_name">
    <tr v-if="!storage.wallet">
      <td class="text-left font-weight-bold" style="border-bottom: 1px solid rgb(255, 255, 255);">{{ field.field_name }}:</td>
      <td style="border-bottom: 1px solid rgb(255, 255, 255);"></td>
      <td style="border-bottom: 1px solid rgb(255, 255, 255);"></td>
    </tr>
    <tr v-if="!storage.wallet">
      <td class="text-left bottom-grey">{{ storage['field'+field.id] }}</td>
      <td class="bottom-grey"></td>
      <td class="bottom-grey"></td>
    </tr>
    </tbody>
    <tbody v-if="storage.wallet">
    <tr>
      <td class="text-left font-weight-bold" style="border-bottom: 1px solid rgb(255, 255, 255);">Wallet:</td>
      <td style="border-bottom: 1px solid rgb(255, 255, 255);"></td>
      <td style="border-bottom: 1px solid rgb(255, 255, 255);"></td>
    </tr>
    <tr>
      <td class="text-left bottom-grey" colspan="3">{{ storage.wallet }}</td>
    </tr>
    </tbody>
    <tbody v-if="storage.description">
    <tr>
      <td class="text-left font-weight-bold" style="border-bottom: 1px solid rgb(255, 255, 255);">Denomination:</td>
      <td style="border-bottom: 1px solid rgb(255, 255, 255);"></td>
      <td style="border-bottom: 1px solid rgb(255, 255, 255);"></td>
    </tr>
    <tr>
      <td class="text-left bottom-grey" colspan="3">{{ storage.description }}</td>
    </tr>
    </tbody>
  <tbody>
    <tr>
      <td class="text-left font-weight-thin font-italic" colspan="4" v-if="storage.provider.commission_ps">
        * Amount includes Commission {{storage.provider.name}} {{storage.provider.commission_ps}} AED Service Fee {{storage.provider.service_fee}}% and VAT {{storage.provider.processing_fee}}% on Service Fee
      </td>
      <td class="text-left font-weight-thin font-italic" colspan="4" v-if="storage.provider.service_fee && !storage.provider.commission_ps">
        * Amount includes Service Fee {{storage.provider.service_fee}}% and VAT {{storage.provider.processing_fee}}% on Service Fee
      </td>
    </tr>
    </tbody>
  </v-table>

  <v-row class="mt-8" no-gutters justify="center">
    <v-col
      cols="12"
      align="center"
    >
      <v-btn
        :disabled="!enabled"
        color="success"
        block
        @click="setToPay"
      >
        ACCEPT & CONTINUE
      </v-btn>
    </v-col>
  </v-row>
  <v-row class="mt-5 texts-pay">
    <v-col>
      <div class="text-center">
        By clicking accept & continue you agree to our
        <a @click="setToRoute('cancellation_refund_policy')" >CANCELLATION & REFUND POLICE</a>
        and
        <a @click="setToRoute('terms_and_conditions')" >TERMS AND CONDITIONS</a>
      </div>
    </v-col>
  </v-row>
</template>

<script> /* eslint-disable */
import { inject, ref } from 'vue'
import router from '@/utils/router'
import axios from '@/utils/axios-http'

export default {
  name: 'PayView',
  setup (props, context) {
    const { storage, setStorage } = inject('storage')
    const { setToRoute } = inject('history')
    let fields = {}
    Object.entries(storage.provider.fields).forEach(([key, value]) => {
      fields[value.field_name] = storage['field'+value.id]
    })

    if (!storage.amount || !storage.provider) {
      router.push({ name: 'home' })
    } else {
      setStorage('overlay', false)
    }

    setStorage('showBack', true)

    let amount = 0

    if (storage.provider.round_type_id === 1) {
      amount = Math.floor(storage.amount * 100) / 100
    } else {
      if (storage.provider.round_type_id === 2) {
        amount = parseInt(storage.amount)
      } else {
        if (storage.provider.round_type_id === 3) {
          amount = Math.floor(parseInt(storage.amount)/5)*5
        }
      }
    }

    if (amount<5) {
      amount = 5
    }

    storage.amount = Number(amount).toFixed(2)

    if (storage.provider.commission_ps) {
      amount = amount + storage.provider.commission_ps
    }

    const serviceFee = amount * storage.provider.service_fee / 100
    const processingFee = serviceFee * storage.provider.processing_fee / 100
    const total = Number(Math.ceil((amount + serviceFee + processingFee) * 100) / 100).toFixed(2)
    const total_amount = Math.ceil((amount + serviceFee + processingFee) * 100) / 100
    const enabled = ref(true)

    const setToPay = () => {
      enabled.value = false
      let session = ''
      let receipt = 0
      if (storage.session){
        session = storage.session
      }
      if (storage.receipt){
        receipt = storage.receipt
      }
      setStorage('overlay', true)
      if (storage.provider.type_id === 5){
        fields['wallet'] = storage.wallet
        fields['number'] = storage.number
      }
      axios.post(process.env.VUE_APP_API_URL + 'noonpayments/init', {
        fields: fields,
        amount: amount,
        total_amount: total_amount,
        provider_id: storage.provider.id,
        session: storage.session,
        receipt: storage.receipt,
        account: storage.account,
        fields_ext: storage.fields_ext
      })
        .then(response => {
          setStorage('overlay', false)
          if (response.data && response.data.url) {
            location = response.data.url
          }
        })
    }

    return {
      storage,
      total,
      enabled,
      setToPay,
      setToRoute
    }
  }
}
</script>
<style>
  main {
    padding: 64px 0px 0px;
  }
  .bottom-grey {
    border-bottom: 1px solid rgb(225, 225, 225);
  }
  .texts-pay a {
    text-decoration: none;
    color: #1940e1;
  }
  .texts-pay {
    font-size: 11px;
  }
</style>
