<template>
  <v-form
    ref="amountForm"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row class="mt-8" no-gutters justify="center">
        <v-col
          cols="5"
          class="rounded-lg ma-2"
          height="200px"
        >
          <v-img
            :src='storage.provider.image_url'
          />
        </v-col>
      </v-row>
      <li style="display: block" v-for="field in storage.provider.fields" :key="field.field_name" >
        <v-row class="mt-8" no-gutters justify="center">
          <v-col
            cols="12"
          >
            <v-text-field
              :label="field.field_comment"
              :hint="field.example"
              persistent-hint
              outlined
              required
              v-model="storage['field'+field.id]"
              class="label-text"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </li>
      <v-row class="mt-8" no-gutters justify="center">
        <v-col
          cols="12"
        >
          <v-text-field
            outlined
            label="Enter amount"
            clearable
            required
            v-model="storage.amount"
            :rules="amountRules"
            prefix="AED"
            class="label-text"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-8" no-gutters justify="center">
        <v-col
          cols="12"
          align="center"
        >
          <v-btn
            :ripple="false"
            @click="prevHistory"
          >
            BACK
          </v-btn>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="setValidate"
          >
            NEXT
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { inject, onMounted, ref } from 'vue'
import router from '@/utils/router'
import { useRoute } from 'vue-router'

export default {
  name: 'PayAmount',
  setup (props, context) {
    const { prevHistory } = inject('history')
    const { storage, setStorage } = inject('storage')
    const amountForm = ref(null)
    const valid = ref(true)

    const route = useRoute()
    const limits = route.query

    const setValidate = val => {
      setStorage('overlay', false)
      router.push({ name: 'pay' })
    }

    const amountRules = [
      v => !!v || 'Amount is required',
      v => (parseFloat(v) >= limits.min) || `Amount must be greater than ${limits.min}`,
      v => (parseFloat(v) <= limits.max) || `Amount must be less than ${limits.max}`
    ]

    onMounted(() => {
      if (storage.amount) {
        amountForm.value.validate()
      }
    })

    return {
      setValidate,
      amountForm,
      prevHistory,
      storage,
      amountRules,
      valid
    }
  }
}
</script>
